




























































































































































































































































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { departmentApi } from "@/api/department";
import { enterpriseApi, IGetEnterprisesParam } from "@/api/enterprise";
import { IUserParams, userApi } from "@/api/user";
import { groupApi, IGroupParams } from "@/api/group";
import { ICreateInstrumentParams, instrumentApi } from "@/api/instrument";
import InstrumentSelect from "@/components/Select/InstrumentSelect.vue";
import FileUpload from "@/components/VueFormMaking/components/Upload/file.vue";
import {
  IInsNumberParams,
  IInsNumberResp,
  insNumberApi,
} from "@/api/ins_number";

@Component({
  components: {
    InstrumentSelect,
    FileUpload,
  },
})
export default class InstrumentCreateForm extends Vue {
  @Prop({ required: true }) value!: ICreateInstrumentParams;
  @Prop({ default: [] }) hideColumns!: any[];
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: {} }) defaultValues!: { [key: string]: any };

  @Ref("createRef") createRef!: any;

  insNumbers = {} as IInsNumberResp;
  insNumber = "";
  users: any = [];
  groups: any = [];
  enterprises: any = [];
  departments: any = [];

  private rules = {
    name: [
      {
        required: true,
        message: "请输入名称",
        trigger: "blur",
      },
    ],
    code: [
      {
        required: true,
        message: "请输入",
        trigger: "blur",
      },
    ],
    departmentId: [
      {
        required: true,
        message: "请选择",
        trigger: "blur",
      },
    ],
    custodianId: [
      {
        required: true,
        message: "请选择",
        trigger: "blur",
      },
    ],
  };
  instrumentFields = [];
  fields = [] as any;
  loading = false;

  get localValue(): ICreateInstrumentParams {
    return this.value;
  }

  set localValue(newVal: ICreateInstrumentParams) {
    this.$emit("input", newVal);
  }

  forceUpdate() {
    this.$forceUpdate();
  }

  @Watch("insNumber")
  setCode() {
    const numberInfo = this.insNumbers.items.find((item) => {
      return item.name === this.insNumber;
    });
    if (!numberInfo) {
      return;
    }
    insNumberApi
      .getInsMaxCode(numberInfo.id)
      .then((maxId) => {
        if (!this.localValue.code) {
          this.localValue.code = `${numberInfo.number}-${maxId + 1}`;
        }
        this.localValue.extraFields[this.fieldId("设备分类")] = this.insNumber;
        this.$forceUpdate();
      })
      .catch((e) => {
        this.$message.warning(`获取最大编号失败, ${e}`);
      });
  }

  @Watch("localValue.extraFields", { deep: true })
  setBuyPay() {
    console.log(this.localValue, "this.localValue");
    if (this.localValue.extraFields[this.fieldId("资产性质")] === "配套") {
      this.localValue.extraFields[this.fieldId("资产购入费用")] = 0;
      this.$forceUpdate();
    }
  }

  getInsNumbers() {
    insNumberApi
      .getInsNumber({
        page: 1,
        perPage: 999999,
        baseOnly: 1
      } as unknown as IInsNumberParams)
      .then((data) => {
        this.insNumbers = data;
        this.insNumber = this.localValue.extraFields[this.fieldId("设备分类")];
      })
      .catch((e) => {
        this.$message.warning(e);
      });
  }

  public async validate() {
    try {
      return await this.createRef.validate();
    } catch (_) {
      return false;
    }
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  get statusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0]);
        return {
          key,
          value: key,
          label: obj[key],
        };
      }) || []
    );
  }

  get visibleColumns() {
    // @ts-ignore
    return UserModule.user.visible_columns;
  }

  async mounted() {
    this.getUsers();
    this.getGroups();
    this.getDepartments();
    this.getEnterprises();
    await this.getFields();
    this.setDefaultValue();
    this.getInsNumbers();
    this.defaultValues = this.defaultValues ? this.defaultValues : {};
    this.hideColumns = this.hideColumns ? this.hideColumns : [];
  }

  setDefaultValue() {
    for (const key in this.defaultValues) {
      const value = this.defaultValues[key];
      const id = this.fieldId(key);
      if (id) {
        // @ts-ignore
        this.localValue.extraFields[id] = value;
        continue;
      }
      // @ts-ignore
      this.localValue[key] = value;
    }
  }

  get showField() {
    const { user, isSuperAdmin }: any = UserModule;
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id);
    return (key: string) => {
      if (this.hideColumns.includes(key)) {
        return false;
      }
      const name = this.fieldName(key);
      if (name && this.hideColumns.includes(name)) {
        return false;
      }
      return this.visibleColumns.includes(key) || isAdmin || isSuperAdmin;
    };
  }

  get config() {
    return UserModule.config;
  }

  private getGroups() {
    groupApi
      .getGroups({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      } as IGroupParams)
      .then((res) => {
        this.groups = res;
      });
  }

  private getDepartments() {
    departmentApi
      .getDepartments({
        name: "",
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
        baseOnly: 1
      })
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {});
  }

  private getEnterprises() {
    enterpriseApi
      .getEnterprises({
        name: "",
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {});
  }

  private getUsers() {
    userApi
      .getUsers({
        page: 1,
        perPage: 999999,
        baseOnly: 1,
        name: "",
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      } as IUserParams)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {});
  }

  fieldId(name: string) {
    // @ts-ignore
    return this.instrumentFields.find((item: any) => item.name == name)?.id;
  }

  fieldName(id: any) {
    // @ts-ignore
    return this.instrumentFields.find((item: any) => item.id == id)?.name;
  }

  private async getFields() {
    const [
      { value: sortRes = [] },
      { value: res = [] },
      { value: extraFields = [] },
    ]: any = await Promise.allSettled([
      enterpriseApi.getFieldsSort({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      }),
      enterpriseApi.getFields({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
      }),
      instrumentApi.getInstrumentFields(),
    ]);

    const fields: any = [
      ...(sortRes?.length ? sortRes : []),
      ...res
        ?.reduce((s: any[], c: any) => {
          if (c instanceof Array) {
            return [...s, ...c];
          }
          return [...s, c];
        }, [])
        .filter(
          (child: any) =>
            !(sortRes?.length ? sortRes : [])?.find(
              (item: any) => child.key === item.key
            )
        ),
    ];
    this.instrumentFields = fields
      ?.filter(({ key }: any) => key !== "extend_info")
      .map((child: any) => {
        const field = extraFields.find((item: any) => item.id == child.key);
        if (field) {
          return {
            ...child,
            ...field,
          };
        }
        return child;
      });
  }

  handleChangeTime(_: any) {
    // console.log(v, "handleChangeTime");
  }
}
