import http, { encodeParams } from '@/utils/request'

export interface IDepartmentParams {
  name: string
  enterpriseId: number
  page: number
  baseOnly: number
  perPage: number
}

export interface IDepartment {
  id: number
  name: string
  address: string
  enterpriseId: number
  enterpriseName: string
  gmtCreate: string
  gmtModify: string
}

export interface IDepartments {
  total: number
  page: number
  perPage: number
  items: Array<IDepartment>
}

export interface ICreateDepartmentParams {
  enterpriseId: number
  managerId: number
  insManageId: number
  insManageIds: number[]
  name: string
  address: string
}

export interface IModifyDepartmentParams extends ICreateDepartmentParams{
  id: number
}

export const departmentApi = {
  getDepartments: function(params: IDepartmentParams): Promise<IDepartments> {
    return http.get('/department' + encodeParams(params))
  },
  createDepartment: function(param: ICreateDepartmentParams): Promise<{ id: number }> {
    return http.post('/department', param)
  },
  modifyDepartment: function(param: IModifyDepartmentParams): Promise<{ id: number }> {
    return http.put(`/department/${param.id}`, param)
  },
  deleteDepartment: function(departmentId: number): Promise<{ id: number }> {
    return http.delete(`/department/${departmentId}`)
  }
}
