import { IPagingParams, IPageResult, StatusItem } from '@/api/base'
import http, { encodeParams } from '@/utils/request'

export interface IProcessQueryParams extends IPagingParams {
  siteId?: number
  isPublish?: number
  visible?: number
}

export interface IProcessDetail {
  createId: number
  formId: number
  id: number
  modifiedTime: string
  createTime: string
  name: string
  siteId: number
  structure: { edges: Array<any>, nodes: Array<any> }
  remarks?: string
  notify?: number
  publishStructure?: { edges: Array<any>, nodes: Array<any> }
  visible?: Boolean
  code: string
}

export interface IFormDetail {
  name: string
  structure: Object
  remarks: string
  createId: number
  id: number
  createTime: string
  modifiedTime: string
}

export interface IFormCreate {
  name: string
  structure: Object
  remarks: string
}

export interface IProcessCreate {
  name: string
  siteId: number
  structure: Object
  remarks: string
  form: IFormCreate
  visible?: Boolean
}
export interface IProcessPagingResult extends IPageResult {
  items: Array<IProcessDetail>
}

export interface IOrderCreate {
  priority: number
  formData: Object
  processId: number
}

export interface IFormData {
  formData: Object
  formStructure: Object
}

export const TransferType = {
  Cretae: new StatusItem(0, '新建', 'info'), // 新建
  Approved: new StatusItem(1, '通过', 'success'), // 通过
  Point: new StatusItem(2, '转交', 'success'), // 转交
  Stopped: new StatusItem(3, '终止', 'warning'), // 终止
  Rejected: new StatusItem(4, '拒绝', 'danger'), // 拒绝
  Canceled: new StatusItem(5, '撤销', 'info'), // 撤销
  End: new StatusItem(6, '结束', 'info'),
  UpdateForm: new StatusItem(7, '更新表单', 'warning'),
  Replay: new StatusItem(8, '重新执行', 'warning')
}

export const OrderType = {
  Stopped: new StatusItem(-4, '终止', 'warning'), // 终止
  Closed: new StatusItem(-3, '关闭', 'warning'), // 关闭
  Rejected: new StatusItem(-2, '拒绝', 'danger'), // 拒绝
  Canceled: new StatusItem(-1, '撤销', 'info'), // 撤销
  Processing: new StatusItem(0, '审批中', ''), // 审批中
  Approved: new StatusItem(1, '通过', 'success') // 通过
}

export const PriorityType = {
  Normal: new StatusItem(1, '正常', ''),
  Emergency: new StatusItem(2, '紧急', 'danger')
}

export interface IHistoryItem {
  createTime: string
  id: number
  modifiedTime: string
  processor: string // 处理人
  processorId: string // 处理人
  remarks: string
  source: string
  state: number
  target: string
}
export interface IOrderDetail extends IOrderCreate {
  title: string
  createId: number
  id: number
  createTime: string
  modifiedTime: string
  formData: IFormData
  state: number
  isTest: boolean
  processData: IProcessDetail
  currentNodes: { [key: string]: { id: string } }
  history: Array<IHistoryItem>
  /* eslint-disable */
  relatedPersons: {
    apply_persons: Array<number> //当前审批人
    notify_persons: Array<number> // 所有的人
    approval_persons: { [key: string]: Array<number> } // 当前节点已经审批通过的人的列表，用于会签
  }
  /* eslint-enable */
}

export interface IOrderPagingResult extends IPageResult {
  items: Array<IOrderDetail>
}

export interface IOrderQueryParams extends IPagingParams {
  siteId?: number
  myOrder?: number
  priority?: number
  state?: number
  creator?: number
  type?: 'todo'
  isTest?: boolean
  visible?: boolean
  createDateRange?: any
}

export const processApis = {
  search(params: IProcessQueryParams): Promise<IProcessPagingResult> {
    return http.get('/process/' + encodeParams(params))
  },
  create(data: IProcessCreate): Promise<IProcessDetail> {
    return http.post('/process/', data)
  },
  getProcess(processId: number): Promise<IProcessDetail> {
    return http.get(`/process/${processId}`)
  },
  updateProcess(processId: number, data: IProcessDetail): Promise<IProcessDetail> {
    return http.put(`/process/${processId}`, data)
  },
  publishProcess(processId: number, isPublish: number, comment: string) {
    return http.put(`/process/publish/${processId}`, { isPublish: isPublish, comment: comment })
  },
  getForm(formId: number): Promise<IFormDetail> {
    return http.get(`/form/${formId}`)
  },
  updateForm(formId: number, data: IFormDetail): Promise<IFormDetail> {
    return http.put(`/form/${formId}`, data)
  },
  createOrder(data: IOrderCreate): Promise<IOrderDetail> {
    return http.post('/order', data)
  },
  createTestOrder(data: IOrderCreate, creatorId: number): Promise<IOrderDetail> {
    return http.post('/v1/order/testcase', { data: data, creatorId: creatorId })
  },
  getChoices(q: string, key: string, method: string, useEnv: boolean, form: Object): Promise<Array<any>> {
    // 从远程rpc接口获取选项
    return http.post('/v1/order/choices', { q: q, key: key, method: method, form: form, useEnv: useEnv })
  },
  searchOrder(params: IOrderQueryParams): Promise<IOrderPagingResult> {
    return http.get('/v1/order' + encodeParams(params))
  },
  getOrder(orderId: number): Promise<IOrderDetail> {
    return http.get(`/v1/order/${orderId}`)
  },
  submitTransfer(
    orderId: number,
    status: number,
    formData: Object,
    comment: string,
    pointPersonIds?: Array<number>,
    approvalUserId?: number
  ) {
    const data = {
      status: status,
      formData: formData,
      comment: comment,
      pointPersonIds: pointPersonIds,
      approvalUserId: approvalUserId
    }
    return http.put(`/v1/order/${orderId}`, data)
  }
}
