import http, { encodeParams } from '@/utils/request'
import { IPageResult } from '@/api/base'
import { IUserDetail } from '@/api/user'

export interface IInsNumberParams {
  name?: string,
  number?: string,
  page: 1,
  perPage: 10
}

export interface ICreateInsNumber {
  name:string,
  number:string,
  international_number:string,
  international_classification: string
}

export interface IInsNumberResp {
  page:number
  perPage: number
  total: number
  items: Array<IInsNumberDetail>
}

export interface IInsNumberDetail extends ICreateInsNumber{
  id: number
  enterpriseId: number
  internationalNumber:string,
  internationalClassification: string
}

export const insNumberApi = {
  getInsNumber: function(params: IInsNumberParams): Promise<IInsNumberResp> {
    return http.get('/ins_number' + encodeParams(params))
  },
  getInsMaxCode: function(numberId: number): Promise<number> {
    return http.get(`/ins_number/max/${numberId}`)
  },
  createInsNumber: function(params: ICreateInsNumber) {
    return http.post('/ins_number', params)
  },
  modifyInsNumber: function(insNumberId: number, params: ICreateInsNumber) {
    return http.put(`/ins_number/${insNumberId}`, params)
  },
  deleteInsNumber: function(insNumberId: number) {
    return http.delete(`/ins_number/${insNumberId}`)
  },
  importInsNumbers: function (file: any) {
    const fd = new FormData()
    fd.append('file', file)
    // @ts-ignore
    return http.post('/ins_number/export', fd, {
      // @ts-ignore 调用回调方法 让浏览器不再报出这个错误
      'Content-Type': 'multipart/form-data'
    })
  },
}
