

























































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { IInstrumentParams, instrumentApi } from '@/api/instrument'
import { UserModule } from '@/store/modules/user'
// @ts-ignore
import _ from 'lodash'
import { IInsForm } from '@/components/adjust_form'

import InstrumentSelect from '@/components/InstrumentSelect.vue'
import DepartmentSelect from '@/components/Select/DepartmentSelect.vue'
import EmployeeSelect from '@/components/Select/EmployeeSelect.vue'

@Component({
  components: {
    InstrumentSelect,
    DepartmentSelect,
    EmployeeSelect
  }
})
export default class InstrumentAdjustSelect extends Vue {
  @Prop({ required: true }) value!: IInsForm[]
  @Prop({ default: false }) multiple!: boolean
  @Prop({ default: '' }) width!: string // 需要带参数，如100px
  @Prop({ default: 'normal' }) size!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: 0 }) multipleLimit!: number
  @Prop({ default: '请输入关键词' }) placeholder!: string
  @Prop({ default: false }) preview!: boolean

  @Ref('ins-select') comInsSelect!: any

  insSelectVisible = false
  allSelectedIns = [] as any
  selectedIns = [] as any

  options: Array<any> = []
  private loading = false

  remoteIns = []

  detailUrl(insId: number) {
    return window.location.protocol + "//" + window.location.host + `/#/ins/detail/${insId}`
  }

  get localValue(): IInsForm[] {
    return this.value
  }

  set localValue(newVal: IInsForm[]) {
    this.$emit('input', newVal)
  }

  insFormat(insId: any) {
    return this.allSelectedIns.find((item: any) => {
      return item.id === insId
    }).name
  }

  previewFormatIns(insId: number) {
    // @ts-ignore
    return this.remoteIns.find((item: any) => {return item.id === insId})?.name
  }

  async getRemoteIns() {
    // 获取已经被选择的列表
    try {
      const result = await instrumentApi.getInstruments({
        ids: this.localValue.map(item => item.ins.toString()).join(",")
      } as IInstrumentParams);
      // @ts-ignore
      this.remoteIns = result.items
    } finally {
      this.loading = false;
    }
  }

  showAddIns() {
    this.insSelectVisible = true
  }

  removeIns(index: number) {
    const insId = this.localValue[index].ins
    this.localValue.splice(index, 1)
    this.comInsSelect.toggleSelection(this.allSelectedIns.find((item: any) => {
      return item.id === insId
    }))
  }

  cancelSelectIns() {
    this.insSelectVisible = false
    this.selectedIns = [] as any
  }

  get ignoreSelectStatus() {
    // @ts-ignore
    return { status: UserModule.config?.noUseStatus }
  }

  instrumentSelect(value: any) {
    this.selectedIns = value
  }

  doSelectIns() {
    const allIds = []
    for (const allIns of this.allSelectedIns) {
      allIds.push(allIns.id)
    }
    for (const sIns of this.selectedIns) {
      if (allIds.indexOf(sIns.id) === -1) {
        this.allSelectedIns.push(sIns)
        this.localValue.push({ ins: sIns.id } as IInsForm)
      }
    }
    this.insSelectVisible = false
  }

  async created() {
    if (this.disabled) {
      await this.getRemoteIns()
    }
  }

  private async getValueItems(ids: Array<any>) {
    // 获取已经被选择的列表
    try {
      const result = await instrumentApi.getInstruments({
        ids: ids.map(item => item.toString()).join(',')
      } as IInstrumentParams)
      const opIds = []
      for (const item of this.options) {
        opIds.push(item.id)
      }
      // @ts-ignore
      for (const item of result.items) {
        if (opIds.indexOf(item.id) === -1) {
          this.options.push(item)
        }
      }
    } finally {
      this.loading = false
    }
    // this.localValue = ids
  }

}
