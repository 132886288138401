










import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import Vditor from "vditor";
import "vditor/dist/index.css";
@Component
export default class extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ default: false }) preview!: boolean; // 预览模式
  @Prop({ default: true }) showBox!: boolean; // 显示阴影
  @Prop({ default: "sv" }) mode!: "sv" | "wysiwyg" | "ir"; // 显示模式

  @Ref("preview-ele") previewEle!: HTMLDivElement;
  @Ref("vditor-ele") vditorEle!: HTMLDivElement;

  get localValue() {
    return this.value;
  }

  set localValue(val: string) {
    this.$emit("input", val);
  }

  private contentEditor: Vditor | null = null;
  private toolbar = [
    "emoji",
    "headings",
    "bold",
    "italic",
    "strike",
    "link",
    "|",
    "list",
    "ordered-list",
    "check",
    "outdent",
    "indent",
    "|",
    "quote",
    "line",
    "code",
    "inline-code",
    "insert-before",
    "insert-after",
    "|",
    "table",
    "|",
    "undo",
    "redo",
    "|",
    "fullscreen",
    "edit-mode",
    {
      name: "more",
      toolbar: [
        "both",
        "code-theme",
        "content-theme",
        "export",
        "outline",
        "preview",
        "devtools",
        "info",
        "help",
      ],
    },
  ];

  mounted() {
    if (this.preview) {
      this.previewText(this.localValue);
    } else {
      this.markdownEdit();
    }
  }

  @Watch("value")
  valChange(val: string) {
    if (this.preview) {
      this.previewText(val);
    }
  }

  private markdownEdit() {
    this.contentEditor = new Vditor(this.vditorEle, {
      height: 360,
      toolbarConfig: {
        pin: true,
      },
      cache: {
        enable: false,
      },
      toolbar: this.toolbar,
      mode: this.mode, // 分屏
      preview: {
        actions: ["desktop", "tablet", "mobile"],
      },
      after: () => {
        if (this.contentEditor && !this.localValue) {
          this.contentEditor.setValue("请开始编辑你的内容");
        }
      },
      input: (val: string) => {
        if (this.contentEditor) {
          this.localValue = val;
        }
      },
      ctrlEnter: () => null,
    });
  }

  private previewText(md: string) {
    Vditor.preview(this.previewEle, md, {
      mode: "light",
      hljs: { style: "github" },
    });
  }
}
