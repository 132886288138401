export interface IPageResult {
  page: number
  perPage: number
  total: number
  items: Array<any>
}

export const encodeParams = (queryParams: any) => {
  if (queryParams) {
    const urlParam = new URLSearchParams()
    for (const key in queryParams) {
      if (queryParams[key] !== '' && typeof queryParams[key] !== 'undefined') {
        urlParam.set(key, queryParams[key])
      }
    }
    return '?' + urlParam.toString()
  } else {
    return ''
  }
}

export interface IPagingParams {
  page: number
  perPage: number
  q: string
}

export class StatusItem {
  value: number | string
  label: string
  tagType: string

  constructor(value: number | string, label: string, tagType: string) {
    this.value = value
    this.label = label
    this.tagType = tagType
  }
}
