







































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import InstrumentCreate from "../InstrumentCreate.vue";
// @ts-ignore
import _ from "lodash";
import { orderApi } from "@/api/order";
import { IUserParams, userApi } from "@/api/user";
import { UserModule } from "@/store/modules/user";
import { departmentApi, IDepartments } from "@/api/department";

@Component({ components: { InstrumentCreate } })
export default class InstrumentTmpSelect extends Vue {
  @Prop({ required: true }) value!: { [key: string]: { [key: string]: any } };
  @Prop({ required: false }) orderId!: number;
  @Prop({ required: false }) disabled?: boolean;
  @Ref("edit") insEdit!: any;

  loading = false;
  departments = {} as IDepartments;
  orderVisible = false;
  openType = "create";
  currentRow = {};

  get localValue() {
    return this.value || []
  }

  set localValue(newVal) {
    console.log(newVal,'---newval')
    this.$emit('input', newVal)
  }

  async mounted() {
    this.loading = true;
    this.getDepartments();
    this.loading = false;
  }

  // @ts-ignore
  onEdit(row) {
    this.openType = "edit";
    this.orderVisible = true;
    this.currentRow = row;
    console.log(row,'open')
  }
  // @ts-ignore
  onDelete(row) {
    // @ts-ignore
    this.localValue = this.localValue.filter(
      // @ts-ignore
      (child) => child.tmp_id !== this.currentRow?.tmp_id
    );
  }
  onCreate() {
    this.openType = "create";
    this.orderVisible = true;
    this.currentRow = {}
  }
  // @ts-ignore
  onSave(value) {
    const v = {}
    Object.keys(value).forEach((key)=>{
      // @ts-ignore
      v[this.formatToLine(key)] = value[key]
    })
    console.log(v);
    if (this.openType === "create") {
      // @ts-ignore
      this.localValue.push({ tmp_id: this.localValue?.length + 1, ...v });
      // @ts-ignore
      this.localValue = this.localValue.concat([])
    } else {
      // @ts-ignore
      this.localValue = this.localValue.map((child) => {
        // @ts-ignore
        if (child.tmp_id === this.currentRow?.tmp_id) {
          return { tmp_id: child.tmp_id, ...v };
        }
        return child;
      });
    }
    this.orderVisible = false;
  }
  onClose() {
    this.orderVisible = false;
  }

  formatDepartmentName(id: number) {
    const dept = this.departments?.items?.find((dep) => {
      return dep.id == id;
    });
    return dept?.name || "/";
  }

  private getDepartments() {
    departmentApi
      .getDepartments({
        name: "",
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          : // @ts-ignore
            UserModule?.user?.enterprise?.id,
        baseOnly: 1
      })
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {});
  }

  formatToLine(value: string) {
    return value.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  formatToHump(value: string) {
    return value.replace(/\_(\w)/g, (_, letter) => letter.toUpperCase());
  }
}
