<template>
  <div>
    <el-upload
      :action="element.options.action"
      :on-success="handleSuccess"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :with-credentials="true"
      multiple
      :limit="element.options.length"
      :headers="element.options.headers"
      :on-exceed="handleExceed"
      :file-list="dataModel"
      :disabled="element.options.disabled || disabled"
      :style="{ width: element.options.width }"
    >
      <div v-if="!preview">
        <el-button
          :size="size || 'small'"
          :disabled="element.options.disabled || disabled"
          type="primary"
          >点击上传</el-button
        >
        <div slot="tip" class="el-upload__tip">{{ element.options.tip }}</div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { fileApi } from "@/api/files";
import { convertRes2Blob } from "@/utils/download";
export default {
  name: "FileUpload",
  // eslint-disable-next-line vue/require-prop-types
  props: ["element", "preview", "dataModel", "size", "disabled"],
  data() {
    return {
      fileListTmp: [],
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileListTmp = fileList;
      this.$emit("fileList", fileList);
    },
    async handlePreview(file) {
      const response = await fileApi.download(file.url);
      convertRes2Blob(response, file.name);
      // window.open(file.url, '_blank')
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `最多允许上传 ${this.element.options.length} 个文件。`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定要移除 ${file.name}？`);
    },
    handleSuccess(response, file, fileList) {
      if (!response.data) {
        this.$message.error("文件上传失败");
      } else {
        this.$message.success("文件上传成功");
        this.fileListTmp.push({
          uid: file.uid,
          name: file.name,
          path: response.data,
          url: response.data,
        });
      }
      this.$emit("fileList", this.fileListTmp);
    },
  },
};
</script>

<style scoped>
.tips {
  font-size: 12px;
}
.download-btn {
  color: #3375ff;
  cursor: pointer;
}
</style>
