<template>
  <el-form-item
    v-if="showStatus"
    :label-width="
      isLabel === false || !widget.options.labelWidthStatus
        ? '0px'
        : widgetLabelWidth + 'px'
    "
    :label="
      isLabel === false ||
      widget.type === 'divider' ||
      !widget.options.labelWidthStatus
        ? ''
        : widget.name
    "
    :prop="propValue"
    :style="subformIndex !== undefined ? { 'margin-bottom': '0' } : {}"
  >
    <template v-if="preview">
      <template v-if="widget.type === 'color'">
        <div
          style="width: 32px; height: 20px; margin-top: 6px; border-radius: 3px"
          :style="{ 'background-color': dataModel }"
        />
      </template>

      <template v-else-if="widget.type === 'switch'">
        <el-switch v-model="dataModel" :disabled="true" />
      </template>

      <template v-else-if="widget.type === 'editor'">
        <div class="previewEditorDiv" v-html="dataModel" />
      </template>

      <template v-else-if="widget.type === 'markdown'">
        <markdown v-model="dataModel" :preview="true" />
      </template>

      <template v-else-if="widget.type === 'file'">
        <div
          v-for="(uploadUrlItem, uploadUrlIndex) of dataModel"
          :key="uploadUrlIndex"
        >
          <i style="color: #909399" class="el-icon-document" />
          <a :href="uploadUrlItem.url" target="_blank">{{
            uploadUrlItem.name
          }}</a>
        </div>
      </template>

      <template v-else-if="widget.type === 'imgupload'">
        <fm-upload
          v-model="dataModel"
          :style="{ width: widget.options.width }"
          :width="widget.options.size.width"
          :height="widget.options.size.height"
          :preview="preview"
        />
      </template>

      <template v-else-if="widget.type === 'rate'">
        <el-rate
          v-model="dataModel"
          :max="widget.options.max"
          :disabled="true"
          :allow-half="widget.options.allowHalf"
        />
      </template>

      <template v-else-if="widget.type === 'divider'">
        <el-divider
          :direction="widget.options.direction"
          :content-position="widget.options.content_position"
        >
          <span
            :style="{
              'font-size': widget.options.font_size,
              'font-family': widget.options.font_family,
              'font-weight': widget.options.font_weight,
              color: widget.options.font_color,
            }"
          >
            {{ widget.options.defaultValue }}
          </span>
        </el-divider>
      </template>

      <template
        v-else-if="widget.type === 'input' && widget.options.showPassword"
      >
        <input
          :value="dataModel"
          type="password"
          style="border: none; background-color: #ffffff; color: #303133"
          disabled="disabled"
        />
      </template>

      <template
        v-else-if="
          widget.type === 'input' &&
          widget.rules &&
          widget.rules.some((item) => item.type === 'url')
        "
      >
        <!-- 如果是url类型的，直接改成url超链接形式 -->
        <el-link
          :href="getUrlWithForm(dataModel)"
          target="_blank"
          type="primary"
        >
          {{ dataModel }}
        </el-link>
      </template>

      <template v-else-if="widget.type === 'cascader'">
        <div>
          {{ dataModel.join(" / ") }}
        </div>
      </template>

      <!-- 下拉选项渲染 -->
      <template
        v-else-if="widget.type === 'select' && Array.isArray(dataModel)"
      >
        <div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="val"
            placement="top-start"
            v-for="(val, idx) of dataModel"
            :key="idx"
          >
            <el-tag size="small" type="info">{{ getSelectLabel(val) }}</el-tag>
          </el-tooltip>
        </div>
      </template>

      <!-- 员工选择器渲染 -->
      <template v-else-if="widget.type === 'employee-select'">
        <employee-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :preview="true"
        ></employee-select>
      </template>

      <!-- 仪器选择器渲染 -->
      <template v-else-if="widget.type === 'instrument-select'">
        <instrument-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :preview="true"
        ></instrument-select>
      </template>

      <!-- 科室选择器渲染 -->
      <template v-else-if="widget.type === 'department-select'">
        <department-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :preview="true"
        ></department-select>
      </template>

      <!-- 科室选择器渲染 -->
      <template v-else-if="widget.type === 'instrument-tmp-select'">
        <instrument-tmp-select
          v-model="dataModel"
          :style="{ width: widget.options.width }"
        ></instrument-tmp-select>
      </template>

      <template v-else-if="widget.type === 'instrument-adjust-select'">
        <instrument-adjust-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :preview="true"
        ></instrument-adjust-select>
      </template>
      <template v-if="widget.type === 'instrument-create-table'">
        <instrument-create-table
        v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :preview="true"
        ></instrument-create-table>
      </template>

      <template v-else-if="widget.type === 'select' && dataModel">
        <div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="dataModel"
            placement="top-start"
          >
            <el-tag size="small" type="info">{{
              getSelectLabel(dataModel)
            }}</el-tag>
          </el-tooltip>
        </div>
      </template>

      <template v-else>
        <div
          :style="{
            'font-size': widget.options.font_size,
            'font-family': widget.options.font_family,
            'font-weight': widget.options.font_weight,
            color: widget.options.font_color,
          }"
        >
          {{ dataModel }}
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="widget.type === 'input'">
        <el-input
          v-if="
            widget.options.dataType === 'number' ||
            widget.options.dataType === 'integer' ||
            widget.options.dataType === 'float'
          "
          v-model.number="dataModel"
          :type="widget.options.dataType"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :disabled="formDisabled"
          :show-password="widget.options.showPassword"
        />
        <el-input
          v-else
          v-model="dataModel"
          :type="widget.options.dataType"
          :disabled="formDisabled"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :show-password="widget.options.showPassword"
        />
      </template>

      <template v-if="widget.type === 'textarea'">
        <el-input
          v-model="dataModel"
          type="textarea"
          :rows="5"
          :disabled="formDisabled"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
        />
      </template>

      <template v-if="widget.type === 'number'">
        <el-input-number
          v-model="dataModel"
          :style="{ width: widget.options.width }"
          :step="widget.options.step"
          controls-position="right"
          :disabled="formDisabled"
        />
      </template>

      <template v-if="widget.type === 'radio'">
        <el-radio-group
          v-model="dataModel"
          :style="{ width: widget.options.width }"
          :disabled="formDisabled"
        >
          <el-radio
            v-for="(item, index) in widget.options.remote
              ? widget.options.remoteOptions
              : widget.options.options"
            :key="index"
            :style="{
              display: widget.options.inline ? 'inline-block' : 'block',
            }"
            :label="item.value"
          >
            <template v-if="widget.options.remote">{{ item.label }}</template>
            <template v-else>{{
              widget.options.showLabel ? item.label : item.value
            }}</template>
          </el-radio>
        </el-radio-group>
      </template>

      <template v-if="widget.type.endsWith('checkbox')">
        <!-- todo: max min加到配置项 -->
        <el-checkbox-group
          v-model="dataModel"
          :style="{ width: widget.options.width }"
          :disabled="formDisabled"
          :max="widget.options.max || 100000"
          :min="widget.options.min || 0"
        >
          <el-checkbox
            v-for="(item, index) in widget.options.remote
              ? widget.options.remoteOptions
              : widget.options.options"
            :key="index"
            :style="{
              display: widget.options.inline ? 'inline-block' : 'block',
            }"
            :label="item.value"
          >
            <template v-if="widget.options.remote">{{ item.label }}</template>
            <template v-else>{{
              widget.options.showLabel ? item.label : item.value
            }}</template>
          </el-checkbox>
        </el-checkbox-group>
      </template>

      <template v-if="widget.type === 'time'">
        <el-time-picker
          v-model="dataModel"
          :is-range="widget.options.isRange"
          :placeholder="widget.options.placeholder"
          :start-placeholder="widget.options.startPlaceholder"
          :end-placeholder="widget.options.endPlaceholder"
          :readonly="widget.options.readonly"
          :disabled="formDisabled"
          :editable="widget.options.editable"
          :clearable="widget.options.clearable"
          :arrow-control="widget.options.arrowControl"
          :value-format="widget.options.format"
          :style="{ width: widget.options.width }"
        />
      </template>

      <template v-if="widget.type === 'date'">
        <el-date-picker
          v-model="dataModel"
          :type="widget.options.type"
          :placeholder="widget.options.placeholder"
          :start-placeholder="widget.options.startPlaceholder"
          :end-placeholder="widget.options.endPlaceholder"
          :readonly="widget.options.readonly"
          :disabled="formDisabled"
          :editable="widget.options.editable"
          :clearable="widget.options.clearable"
          :value-format="
            widget.options.timestamp ? 'timestamp' : widget.options.format
          "
          :format="widget.options.format"
          :style="{ width: widget.options.width }"
        />
      </template>

      <template v-if="widget.type === 'rate'">
        <el-rate
          v-model="dataModel"
          :max="widget.options.max"
          :disabled="formDisabled"
          :allow-half="widget.options.allowHalf"
        />
      </template>

      <template v-if="widget.type === 'color'">
        <el-color-picker
          v-model="dataModel"
          :disabled="formDisabled"
          :show-alpha="widget.options.showAlpha"
        />
      </template>

      <template v-if="widget.type === 'select'">
        <el-select
          style="max-width: 500px"
          v-model="dataModel"
          :disabled="formDisabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :filterable="widget.options.filterable"
          :loading="remoteLoading"
          :remote-method="
            (v) => {
              if (v) {
                remoteSearch(v);
              }
            }
          "
          :remote="widget.options.remoteable && widget.options.remote"
          @focus="
            (v) => {
              if (!dataModel || dataModel.length === 0) {
                remoteSearch('');
              }
            }
          "
        >
          <el-option
            v-for="item in widget.options.remoteable && widget.options.remote
              ? remoteTmpOptions
              : widget.options.options"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
            <span style="float: left">{{ item.value }}</span>
            <span
              style="
                float: right;
                color: #8492a6;
                font-size: 13px;
                max-width: 350px;
                overflow: hidden;
              "
              v-show="widget.options.showLabel || widget.options.remote"
            >
              {{ item.label }}
            </span>
          </el-option>
        </el-select>
      </template>

      <template v-if="widget.type === 'switch'">
        <el-switch v-model="dataModel" :disabled="formDisabled" />
      </template>

      <template v-if="widget.type === 'slider'">
        <el-slider
          v-model="dataModel"
          :min="widget.options.min"
          :max="widget.options.max"
          :disabled="formDisabled"
          :step="widget.options.step"
          :show-input="widget.options.showInput"
          :range="widget.options.range"
          :style="{ width: widget.options.width }"
        />
      </template>

      <template v-if="widget.type === 'imgupload'">
        <fm-upload
          v-model="dataModel"
          :disabled="formDisabled"
          :style="{ width: widget.options.width }"
          :width="widget.options.size.width"
          :height="widget.options.size.height"
          :token="widget.options.token"
          :domain="widget.options.domain"
          :multiple="widget.options.multiple"
          :length="widget.options.length"
          :is-qiniu="widget.options.isQiniu"
          :is-delete="widget.options.isDelete"
          :min="widget.options.min"
          :is-edit="widget.options.isEdit"
          :action="widget.options.action"
        />
      </template>

      <template v-if="widget.type === 'file'">
        <file-upload
          :element="widget"
          :data-model="dataModel"
          @fileList="fileList"
        />
      </template>
      <template v-if="widget.type === 'instrument-file'">
        <InstrumentFile
          :orderId="orderId"
          :element="widget"
          :data-model="dataModel"
          @fileList="fileList"
        />
      </template>

      <template v-if="widget.type === 'editor'">
        <vue-editor
          v-model="dataModel"
          :disabled="formDisabled"
          :style="{ width: widget.options.width }"
        />
      </template>

      <template v-if="widget.type === 'markdown'">
        <markdown
          v-model="dataModel"
          :disabled="formDisabled"
          :style="{ width: widget.options.width }"
        />
      </template>

      <template v-if="widget.type === 'cascader'">
        <el-cascader
          v-model="dataModel"
          :disabled="formDisabled"
          :show-all-levels="widget.options.showAllLevels"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
          :options="
            widget.options.remoteable && widget.options.remote
              ? remoteTmpOptions
              : widget.options.options
          "
          :filterable="true"
          :remote="widget.options.remoteable && widget.options.remote"
          v-loading="remoteLoading"
        />
      </template>

      <template v-if="widget.type === 'text'">
        <span
          :style="{
            'font-size': widget.options.font_size,
            'font-family': widget.options.font_family,
            'font-weight': widget.options.font_weight,
            color: widget.options.font_color,
          }"
        >
          {{ widget.options.defaultValue }}
        </span>
      </template>

      <template v-if="widget.type === 'divider'">
        <el-divider
          :direction="widget.options.direction"
          :content-position="widget.options.content_position"
        >
          <span
            :style="{
              'font-size': widget.options.font_size,
              'font-family': widget.options.font_family,
              'font-weight': widget.options.font_weight,
              color: widget.options.font_color,
            }"
          >
            {{ widget.options.defaultValue }}
          </span>
        </el-divider>
      </template>

      <template v-if="widget.type === 'employee-select'">
        <employee-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
        ></employee-select>
      </template>

      <template v-if="widget.type === 'instrument-select'">
        <instrument-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :custodianByMe="widget.options.custodianByMe"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
        ></instrument-select>
      </template>
      <template v-if="widget.type === 'department-select'">
        <department-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
        ></department-select>
      </template>
      <template v-if="widget.type === 'instrument-tmp-select'">
        <instrument-tmp-select
          v-model="dataModel"
          :disabled="widget.options.disabled"
          :order-id="orderId"
          :style="{ width: widget.options.width }"
        ></instrument-tmp-select>
      </template>

      <template v-if="widget.type === 'instrument-adjust-select'">
        <instrument-adjust-select
          v-model="dataModel"
          :disabled="formDisabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
        ></instrument-adjust-select>
      </template>
      <template v-if="widget.type === 'instrument-create-table'">
        <instrument-create-table
          v-model="dataModel"
          :disabled="formDisabled"
          :multiple="widget.options.multiple"
          :clearable="widget.options.clearable"
          :placeholder="widget.options.placeholder"
          :style="{ width: widget.options.width }"
        ></instrument-create-table>
      </template>
    </template>
  </el-form-item>
</template>

<script>
import FmUpload from "./Upload";
import FileUpload from "./Upload/file";
import InstrumentFile from "./Upload/InstrumentFile";
import Markdown from "@/components/Markdown/index.vue";
import EmployeeSelect from "@/components/Select/EmployeeSelect.vue";
import InstrumentSelect from "@/components/Select/InstrumentSelect.vue";
import DepartmentSelect from "@/components/Select/DepartmentSelect.vue";
import InstrumentTmpSelect from "@/components/Select/InstrumentTmpSelect.vue";
import InstrumentAdjustSelect from "@/components/Select/InstrumentAdjustSelect.vue";
import InstrumentCreateTable from "@/components/Select/InstrumentCreateTable.vue";

export default {
  name: "GenetateFormItem",
  components: {
    InstrumentTmpSelect,
    FmUpload,
    FileUpload,
    InstrumentFile,
    Markdown,
    EmployeeSelect,
    InstrumentSelect,
    DepartmentSelect,
    InstrumentAdjustSelect,
    InstrumentCreateTable,
  },
  /* eslint-disable */
  props: [
    "widget",
    "models",
    "propValue",
    "remote",
    "data",
    "disabled",
    "preview",
    "isLabel",
    "subformIndex",
    "subformModel",
    "process",
    "orderId",
  ],
  data() {
    return {
      showStatus: true,
      widgetLabelWidth: "",
      dataModel:
        this.subformIndex === undefined
          ? this.models[this.widget.model]
          : this.models[this.subformModel][this.subformIndex][
              this.widget.model
            ],
      tableData: [],
      remoteTmpOptions: [],
      remoteLoading: false,
    };
  },
  watch: {
    dataModel: {
      deep: true,
      handler(newValue) {
        if (newValue !== undefined && newValue !== null) {
          if (this.subformIndex !== undefined) {
            this.models[this.subformModel][this.subformIndex][
              this.widget.model
            ] = newValue;
            this.$emit("update:models", {
              ...this.models,
              [this.subformModel]: this.models[this.subformModel],
            });
            // this.$emit('input-change', val, this.widget.model, this.subformIndex)
          } else {
            this.models[this.widget.model] = newValue;
            this.$emit("update:models", {
              ...this.models,
              [this.widget.model]: newValue,
            });
            // this.$emit('input-change', val, this.widget.model)
          }
        }
      },
    },
    models: {
      deep: true,
      handler(val) {
        if (!val.status) {
          if (this.subformIndex === undefined) {
            this.dataModel = val[this.widget.model];
          } else {
            this.dataModel =
              val[this.subformModel][this.subformIndex][this.widget.model];
          }
        }
        delete this.models.status;
        this.handleDisplayVerifiy();
      },
    },
    modelsJson: {
      deep: true,
      handler(newVal, oldVal) {
        // 用于表单联动，必须要用modelsJson，不然无法比较出区别
        if (this.widget.options.watchKeys) {
          this.widget.options.watchKeys.split(",").forEach((key) => {
            if (JSON.stringify(newVal[key]) !== JSON.stringify(oldVal[key])) {
              this.dataModel = this.widget.options.defaultValue;
              return;
            }
          });
        }
      },
    },
  },
  created() {
    if (this.widget.options.remote && this.widget.type === "cascader") {
      this.remoteSearch("");
    }

    if (this.widget.type === "imgupload" && this.widget.options.isQiniu) {
      this.remote[this.widget.options.tokenFunc]((data) => {
        this.widget.options.token = data;
      });
    }

    if (this.disabled !== undefined && this.disabled !== null) {
      this.widget.options.disabled = this.disabled;
    }

    // label width
    if (this.widget.options.labelWidthDisabled) {
      this.widgetLabelWidth = this.widget.options.labelWidth;
    } else if (this.widget.type === "divider") {
      this.widgetLabelWidth = 0;
    } else {
      this.widgetLabelWidth = this.data.config.labelWidth;
    }

    this.handleDisplayVerifiy();
  },
  methods: {
    fileList(files) {
      this.dataModel = files;
    },
    handleDisplayVerifiy() {
      if (Object.keys(this.widget.options).indexOf("displayVerifiy") >= 0) {
        if (this.widget.options.displayVerifiy.type !== "hide") {
          var c = 0;
          for (var v of this.widget.options.displayVerifiy.list) {
            if (this.models[v.model].toString() === v.value) {
              c++;
            }
          }
          if (this.widget.options.displayVerifiy.type === "and") {
            if (c !== this.widget.options.displayVerifiy.list.length) {
              this.showStatus = false;
            } else {
              this.showStatus = true;
            }
          } else if (this.widget.options.displayVerifiy.type === "or") {
            if (c === 0) {
              this.showStatus = false;
            } else {
              this.showStatus = true;
            }
          }
        }
      }
    },
    remoteSearch(value) {
      if (
        this.widget.options.remoteable &&
        this.widget.options.remote &&
        this.widget.options.remoteServiceKey &&
        this.widget.options.remoteServiceMethod
      ) {
        this.remoteLoading = true;
        this.remote(
          this.widget.options.remoteServiceKey,
          this.widget.options.remoteServiceMethod,
          value,
          this.widget.options.useEnv,
          this.models
        )
          .then((options) => {
            this.remoteTmpOptions = options.map((item) => {
              return {
                value: item[this.widget.options.props.value],
                label: item[this.widget.options.props.label],
                children: item[this.widget.options.props.children],
              };
            });
          })
          .catch((e) => {
            this.$message.error(`远程调用失败:${JSON.stringify(e)}`);
          })
          .finally(() => {
            this.remoteLoading = false;
          });
      }
    },
    remoteSelectChange(value) {
      // 当选择远程方法的option时，将已经选择的写入到remoteOptions中备用
      if (Array.isArray(value)) {
        let selectValue = {};
        this.widget.options.remoteOptions = [
          ...this.widget.options.remoteOptions,
          ...this.remoteTmpOptions,
        ].filter((item) => {
          if (!selectValue[item.value] && value.includes(item.value)) {
            selectValue[item.value] = true;
            return true;
          }
          return false;
        });
      } else {
        this.widget.options.remoteOptions = this.remoteTmpOptions.filter(
          (item) => value === item.value
        );
      }
    },
    getSelectLabel(val) {
      const matchItems = this.widget.options.options.filter(
        (item) => item.value === val
      );
      if (matchItems.length > 0) {
        const label = matchItems[0][this.widget.options.props.label];
        if (!this.widget.options.showValue) {
          return label;
        }
        if (this.widget.options.remote) {
          return `${val} [${label}]`;
        }
        return label;
      }
      console.warn(`${val}不存在标签`);
      return val;
    },
    getUrlWithForm(data) {
      // 传入输入的url地址，然后拼接字段
      // 为了适应特殊需求加的，请勿随便乱动
      if (this.$route.query.orderId) {
        return data + "?" + `orderId=${this.$route.query.orderId}`;
      }
      return data;
    },
  },
  computed: {
    formDisabled: function () {
      //是否在流程中
      if (this.process) {
        return !this.widget.options.changeInFlow;
      }
      return this.widget.options.disabled;
    },
    modelsJson: function () {
      // 对 obj 进行深拷贝
      return this.models ? JSON.parse(JSON.stringify(this.models)) : {};
    },
  },
};
</script>

<style>
.previewEditorDiv > p {
  margin: 0;
}

.preview-cascader-class .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border: none;
  color: #303133;
}

.preview-cascader-class
  .el-input.is-disabled
  .el-input__suffix
  .el-input__suffix-inner
  .el-input__icon.el-icon-arrow-down:before {
  content: "";
}
</style>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.el-tag {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
// ::v-deep .el-form-item__label {
//   // font-weight: bold;
// }
</style>
