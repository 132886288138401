






































import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {IInstrumentParams, instrumentApi} from "@/api/instrument";
import { UserModule } from "@/store/modules/user";
// @ts-ignore
import _ from "lodash";

@Component
export default class InstrumentSelect extends Vue {
  @Prop({ required: true }) value!: Array<number> | number;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: "" }) width!: string; // 需要带参数，如100px
  @Prop({ default: "normal" }) size!: string;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: 0 }) multipleLimit!: number;
  @Prop({ default: "请输入关键词" }) placeholder!: string;
  @Prop({ default: false }) preview!: boolean;
  @Prop() custodianByMe: boolean | undefined
  @Prop({ default: null }) condition!: {};

  options: Array<any> = [];
  private loading = false;

  get localValue(): Array<number> | number | null {
    if (!this.multiple && Array.isArray(this.value) && this.value.length > 0) {
      return this.value[0]
    }
    return this.value;
  }

  set localValue(newVal: Array<number> | number | null) {
    this.$emit("input", newVal);
  }

  get previewList() {
    const selectList = Array.isArray(this.value) ? this.value : [this.value];
    return this.options.filter((i) => selectList.includes(i.id));
  }

  async created() {
    await this.remoteSearch("");
    if (this.value && Array.isArray(this.value)) {
      await this.getValueItems(this.value);
    } else if (this.value) {
      await this.getValueItems([this.value]);
    }
  }

  private async getValueItems(ids: Array<number>) {
    // 获取已经被选择的列表
    try {
      const result = await instrumentApi.getInstruments({
        ids: ids.map(item => item.toString()).join(",")
      } as IInstrumentParams);
      // @ts-ignore
      this.options.push(...result.items);
    } finally {
      this.loading = false;
    }
    this.localValue = ids
  }

  private focusSearch() {
    if (!this.value) {
      this.remoteSearch("");
    }
  }

  get showOptions() {
    const config = UserModule?.config;
    return (disabled: any) =>
      disabled
        ? this.options
        : this.options
          // .filter(
          //   // @ts-ignore
          //   (child) => !config?.noUseStatus?.includes(child.status)
          // );
  }

  private async remoteSearch(value: string) {
    // const enableSearch = value !== "" || !this.localValue;
    // if (enableSearch) {
    this.loading = true;
    try {
      const condition:any = this.condition ? this.condition : {}
      if(this.custodianByMe) {
        condition["custodianId"] = UserModule.user.id
      }
      const result = await instrumentApi.getInstruments({
        nameOrCode: value,
        ...condition
      } as IInstrumentParams);
      // @ts-ignore
      this.options = result.items;
    } finally {
      this.loading = false;
    }
    // }
  }

  get _style() {
    return `width: ${this.width || "auto"}`;
  }
}
