

















































































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { orderApi } from "@/api/order";
import InstrumentSelect from "@/components/Select/InstrumentSelect.vue";
import FileUpload from "@/components/VueFormMaking/components/Upload/file.vue";
import InstrumentCreateForm from "@/components/InstrumentCreateForm.vue";

@Component({
  components: {
    InstrumentSelect,
    FileUpload,
    InstrumentCreateForm,
  },
})
export default class InstrumentCreate extends Vue {
  @Prop() value: any;
  @Prop({ required: false }) isApprovalUser!: boolean;
  @Prop({ required: false }) disabled?: boolean;
  @Prop({ required: false }) initValue?: any;
  @Prop({ required: false }) insMap!: { id: any; name: any }[];

  @Ref("createModifyRef") instrumentCreateForm!: InstrumentCreateForm;
  private createModifyParams: any = {
    departmentId: null,
    name: "",
    code: "",
    extraFields: {},
  };
  createModifyLoad = false;
  isSubIns = false;
  loading = false;

  uploadOption = {
    options: {
      defaultValue: [],
      width: "",
      tokenFunc: "funcGetToken",
      token: "",
      domain: `${process.env.VUE_APP_BASE_API}`,
      disabled: false,
      required: true,
      length: 1,
      headers: {},
      // isQiniu: false,
      tip: "", // 提示说明
      action: `${process.env.VUE_APP_BASE_API}/file/upload`,
      labelWidth: 100,
      labelWidthDisabled: false,
      labelWidthStatus: true,
      changeInFlow: false,
      displayVerifiy: {
        showInNotify: true,
        type: "hide",
        list: [
          {
            model: "字段标识",
            value: "字段值",
          },
        ],
      },
    },
  };

  get filterInsMap() {
    return this.insMap?.filter((item) => {
      return item.id !== this.createModifyParams.webTmpId;
    });
  }
  // @Watch('initValue')
  // handleInitValue(v) {
  //   Object.keys(v).forEach((key)=>{
  //     this.createModifyParams[this.formatToHump(key)] = v[key]
  //   })
  //   console.log(v,this.createModifyParams,'-------')
  // }

  mounted() {
    Object.keys(this.initValue).forEach((key) => {
      this.createModifyParams[this.formatToHump(key)] = this.initValue[key];
    });
    console.log(this.initValue, this.createModifyParams, "-------");
  }

  async onOk() {
    if (!(await this.instrumentCreateForm.validate())) {
      this.$message.warning("请检查表单");
      return;
    }
    this.$emit("save", this.createModifyParams);
  }
  onCancel() {
    this.$emit("close");
  }

  formatToHump(value: string) {
    return value.replace(/\_(\w)/g, (_, letter) => letter.toUpperCase());
  }
}
