import http, { encodeParams } from '@/utils/request'

export interface ICreateFilesParams {
  brand: string
  model: number
  files: number
}

export const fileApi = {
  getFiles: function (params: any) {
    return http.get('/file/' + encodeParams(params))
  },
  createFiles: function (params: ICreateFilesParams) {
    return http.post('/file/', params)
  },
  upload: function (file: any) {
    const fd = new FormData()
    fd.append('file', file)
    return http.post('/file/upload', fd, {
      // @ts-ignore 调用回调方法 让浏览器不再报出这个错误
      'Content-Type': 'multipart/form-data'
    })
  },
  download: function (name: any) {
    return http.get(`/file/download/${name}`, { responseType: 'blob' })
  },
  delete: function (id: number) {
    return http.delete(`/file/${id}`)
  },
}
