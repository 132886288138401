











































































































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { orderApi } from "@/api/order";
import InstrumentSelect from "@/components/Select/InstrumentSelect.vue";
import FileUpload from "@/components/VueFormMaking/components/Upload/file.vue";
import InstrumentCreateForm from "@/components/InstrumentCreateForm.vue";

@Component({
  components: {
    InstrumentSelect,
    FileUpload,
    InstrumentCreateForm,
  },
})
export default class InstrumentEdit extends Vue {
  @Prop() value: any;
  @Prop() closeDetail: any;
  @Prop({ required: true }) orderId!: number;
  @Prop({ required: true }) save!: any;
  @Prop({ required: true }) allIns!: any[];
  @Prop({ required: false }) isApprovalUser!: boolean;
  @Prop({ required: false }) disabled?: boolean;
  @Prop({ required: false }) insMap!: { id: any; name: any }[];
  @Ref("createModifyRef") instrumentCreateForm!: InstrumentCreateForm;
  users: any = [];
  groups: any = [];
  enterprises: any = [];
  departments: any = [];
  private openType = "create";
  private visible = false;
  private createModifyParams: any = {
    departmentId: null,
    name: "",
    code: "",
    extraFields: {},
  };
  createModifyLoad = false;
  isSubIns = false;
  private rules = {
    name: [
      {
        required: true,
        message: "请输入名称",
        trigger: "blur",
      },
    ],
    code: [
      {
        required: true,
        message: "请输入",
        trigger: "blur",
      },
    ],
    departmentId: [
      {
        required: true,
        message: "请选择",
        trigger: "blur",
      },
    ],
    custodianId: [
      {
        required: true,
        message: "请选择",
        trigger: "blur",
      },
    ],
  };
  saveLoading = false;
  loading = false;

  uploadOption = {
    options: {
      defaultValue: [],
      width: "",
      tokenFunc: "funcGetToken",
      token: "",
      domain: `${process.env.VUE_APP_BASE_API}`,
      disabled: false,
      required: true,
      length: 1,
      headers: {},
      // isQiniu: false,
      tip: "", // 提示说明
      action: `${process.env.VUE_APP_BASE_API}/file/upload`,
      labelWidth: 100,
      labelWidthDisabled: false,
      labelWidthStatus: true,
      changeInFlow: false,
      displayVerifiy: {
        showInNotify: true,
        type: "hide",
        list: [
          {
            model: "字段标识",
            value: "字段值",
          },
        ],
      },
    },
  };

  get actionButtonShow() {
    if (this.isApprovalUser) {
      if (!this.createModifyParams.isPoint) {
        return true
      }
      if (!this.createModifyParams.isSave && this.createModifyParams["custodianId"] === UserModule.user.id) {
        return true
      }
    }
    return false
  }

  fileList(files: any) {
    this.createModifyParams.files = files;
  }

  reports(files: any) {
    this.createModifyParams.report = files;
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  get insList() {
    return this.allIns.filter((item: any) => {
      return Number(item.webTmpId) !== Number(this.createModifyParams.webTmpId);
    });
  }

  formatToHump(value: string) {
    return value.replace(/\_(\w)/g, (_, letter) => letter.toUpperCase());
  }

  formatToLine(value: string) {
    return value.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  async mounted() {
    this.loading = true;
    const value = {} as { [key: string]: any };
    for (const key in this.value) {
      value[this.formatToHump(key)] = this.value[key];
    }
    this.createModifyParams = {
      // @ts-ignore
      enterpriseId: this.isSuperAdmin
        ? undefined
        // @ts-ignore
        : UserModule.user.enterprise.id,
      ...value,
      // @ts-ignore
      extraFields: this.value.extra_fields ? this.value.extra_fields : {},
      // @ts-ignore
      deviceGroupId: value.deviceGroupId || null,
    };
    this.loading = false;
    if (this.createModifyParams.parentId) {
      this.isSubIns = true;
    }
  }

  get insForm() {
    const value = {} as any;
    for (const key in this.createModifyParams) {
      if (key === "webTmpId") {
        continue;
      }
      value[this.formatToLine(key)] = this.createModifyParams[key];
    }
    const data = {} as any;
    if (isNaN(Number(this.value["webTmpId"]))) {
      return data;
    }
    data[Number(this.value["webTmpId"])] = value;
    return data;
  }

  get insCustodianId() {
    if (this.createModifyParams["custodianId"]) {
      return [this.createModifyParams["custodianId"]];
    }
    return [];
  }

  @Watch("createModifyParams", { deep: true })
  watchCreateModifyParams() {
    this.$emit("update:value", this.insForm);
  }

  async doSave() {
    const form = this.insForm;
    if (!form) {
      this.$message.warning("数据未识别");
      return;
    }
    this.saveLoading = true;
    try {
      // 暂存更新form
      if (!this.isApprovalUser) {
        Object.keys(form).forEach(
          (key) => (form[key] = { ...form[key], is_save: true })
        );
      }
      await this.save({
        id: this.orderId,
        status: 2,
        formData: form,
        pointPersonIds: this.insCustodianId,
      });
      this.$message.success("成功");
      this.saveLoading = false;
      this.$emit("update:success", {
        ...this.createModifyParams,
        is_save: !this.isApprovalUser ? true : undefined,
      });
      this.$emit("update:value", this.insForm);
    } catch (e) {
      this.$message.warning(`失败, ${e}`);
      this.saveLoading = false;
    }
  }

  async doAction() {
    // @ts-ignore
    if (!(await this.instrumentCreateForm.validate())) {
      this.$message.warning("请检查表单");
      return;
    }
    const form = {...this.insForm}
    for (const key in form) {
      if (form[key]["custodian_id"] === UserModule.user.id) {
        form[key]["is_save"] = true
      }
    }
    this.createModifyLoad = true;
    orderApi
      .modifyOrder({
        id: this.orderId,
        status: 2,
        formData: form,
        // @ts-ignore
        pointPersonIds: this.insCustodianId,
      })
      .then(() => {
        this.$message.success("下发成功");
        this.visible = false;
        this.createModifyLoad = false;
        this.$emit("update:success", {
          ...this.createModifyParams,
          is_point: true,
          is_save: true
        });
      })
      .catch((e) => {
        this.$message.warning(`下发失败, ${e}`);
      })
      .finally(() => {
        this.createModifyLoad = false;
      });
  }
}
