var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{attrs:{"data":_vm.localValue,"stripe":""}},[_c('el-table-column',{attrs:{"label":"仪器"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.disabled)?_c('div',[_vm._v(_vm._s(_vm.insFormat(row.ins)))]):_c('div',[_vm._v(_vm._s(_vm.previewFormatIns(row.ins)))])]}}])}),_c('el-table-column',{attrs:{"label":"调整后部门","prop":"department"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('DepartmentSelect',{attrs:{"width":"auto"},model:{value:(row.department),callback:function ($$v) {_vm.$set(row, "department", $$v)},expression:"row.department"}})]}}])}),_c('el-table-column',{attrs:{"label":"调整后保管员","prop":"custodian"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('EmployeeSelect',{attrs:{"department":row.department,"width":"auto"},model:{value:(row.custodian),callback:function ($$v) {_vm.$set(row, "custodian", $$v)},expression:"row.custodian"}})]}}])}),_c('el-table-column',{attrs:{"label":"调整后存放位置","prop":"deposit_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","placeholder":"不能为空"},model:{value:(row.deposit_address),callback:function ($$v) {_vm.$set(row, "deposit_address", $$v)},expression:"row.deposit_address"}})]}}])}),_c('el-table-column',{attrs:{"label":"设备详情","prop":"deposit_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"type":"primary","target":"_blank","href":_vm.detailUrl(row.ins)}},[_vm._v("查看详情")])]}}])}),(!_vm.disabled)?_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.removeIns(scope.$index)}}},[_vm._v("删除")])]}}],null,false,2743065249)},[_c('template',{slot:"header"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.showAddIns}},[_vm._v("新增")])],1)],2):_vm._e()],1),_c('el-dialog',{attrs:{"title":"选择仪器","visible":_vm.insSelectVisible,"close-on-click-modal":false,"append-to-body":"","top":"5vh","width":"80%"},on:{"close":_vm.cancelSelectIns}},[_c('InstrumentSelect',{ref:"ins-select",attrs:{"custodian-by-me":true,"ignore-search":['custodianId'],"ignore-select":_vm.ignoreSelectStatus},on:{"update:value":_vm.instrumentSelect},model:{value:(_vm.allSelectedIns),callback:function ($$v) {_vm.allSelectedIns=$$v},expression:"allSelectedIns"}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.cancelSelectIns}},[_vm._v("关 闭")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.doSelectIns}},[_vm._v("确 认 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }